<template>
    <main class="wrapper">
      <section class="loaders loaders-bg-2">
        <span class="loader loader-double"> </span>
        <span class="loader-txt">Loading...</span>
      </section>
    </main>
  </template>
  
  <script>
  export default {
    name: 'AmaraLoader'
  };
  </script>
  
  <style lang="scss" scoped>
  $color: #7374a4;
  $colorRight: #9697bb;
  $colorLeft: #b9b9d1;
  $shadow: #DBE3F4;
  .am-loader-wrap {
    height: 80vh;
    display: flex;
    place-content: center;
    place-items: center;
  }
  .boxes {
    --size: 54px;
    --duration: 800ms;
    height: calc(var(--size) * 2);
    width: calc(var(--size) * 3);
    position: relative;
    transform-style: preserve-3d;
    transform-origin: 50% 50%;
    transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
    .box {
      width: var(--size);
      height: var(--size);
      top: 0;
      left: 0;
      position: absolute;
      transform-style: preserve-3d;
      &:nth-child(1) {
          transform: translate(100%, 0);
          animation: box1 var(--duration) linear infinite;
      }
      &:nth-child(2) {
          transform: translate(0, 100%);
          animation: box2 var(--duration) linear infinite;
      }
      &:nth-child(3) {
          transform: translate(100%, 100%);
          animation: box3 var(--duration) linear infinite;
      }
      &:nth-child(4) {
          transform: translate(200%, 0);
          animation: box4 var(--duration) linear infinite;
      }
      & > div {
          --background: #{$color};
          --top: auto;
          --right: auto;
          --bottom: auto;
          --left: auto;
          --translateZ: calc(var(--size) / 2);
          --rotateY: 0deg;
          --rotateX: 0deg;
          position: absolute;
          width: 100%;
          height: 100%;
          background: var(--background);
          top: var(--top);
          right: var(--right);
          bottom: var(--bottom);
          left: var(--left);
          transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
          &:nth-child(1) {
              --top: 0;
              --left: 0;
          }
          &:nth-child(2) {
              --background: #{$colorRight};
              --right: 0;
              --rotateY: 90deg;
          }
          &:nth-child(3) {
              --background: #{$colorLeft};
              --rotateX: -90deg;
          }
          &:nth-child(4) {
              --background: #{$shadow};
              --top: 0;
              --left: 0;
              --translateZ: calc(var(--size) * 3 * -1);
          }
      }
    }
  }
  
  @keyframes box1 {
      0%,
      50% {
          transform: translate(100%, 0);
      }
      100% {
          transform: translate(200%, 0);
      }
  }
  
  @keyframes box2 {
      0%{
          transform: translate(0, 100%);
      }
      50% {
          transform: translate(0, 0);
      }
      100% {
          transform: translate(100%, 0);
      }
  }
  
  @keyframes box3 {
      0%,
      50% {
          transform: translate(100%, 100%);
      }
      100% {
          transform: translate(0, 100%);
      }
  }
  
  @keyframes box4 {
      0%{
          transform: translate(200%, 0);
      }
      50% {
          transform: translate(200%, 100%);
      }
      100% {
          transform: translate(100%, 100%);
      }
  }
  </style>
  
  <style lang="scss" scoped>
  // $Var
  $bg-color: #FFFF33;
  $bg-color-1: #0066CC;
  $bg-color-2: #FC4532;
  $bg-color-3: #FF29C8;
  $bg-color-4: #0000FF;
  $color: #ffffff;
  
  // $Mixin
  @mixin size($width , $height: $width ) {
    width: $width;
    height: $height;
  }
  
  @mixin fontsize($size: 24, $base: 16) {
    font-size: $size + px;
    font-size: ($size / $base) * 1rem;
  }
  
  @mixin absPosition ($top: auto, $right: auto, $bottom: auto, $left: auto) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }
  
  @mixin opacity($opacity) {
    opacity: $opacity;
    $opacityIE: $opacity * 100;
    filter: alpha(opacity=$opacityIE);
  }
  
  @mixin animation($animation...) {
    -webkit-animation: $animation;
    animation: $animation;
  }
  
  .loaders-bg-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .loader {
    display: inline-block;
    position: relative;
    height: 150px;
    width: 150px;
    vertical-align: middle;
  }
  /*$Loader Double circle
  ========================================================================== */
  
  .loader-double {
    border-radius: 50%;
    border: 6px solid #050550;
    border-top-color: $color;
    border-bottom-color: $color;
    @include animation(spin 1.5s linear infinite);
    &:before,
    &:after {
      content: "";
      @include absPosition(5px, 5px, 5px, 5px);
      border-radius: 50%;
      border: 6px solid #050550;
      border-top-color: $color;
      border-bottom-color: $color;
      @include opacity(1);
      @include animation(spinreverse 2s linear infinite);
    }
    &:before {
      top: 13px;
      left: 13px;
      bottom: 13px;
      right: 13px;
      @include animation(spinreverse 3s linear infinite);
    }
  }
  .loader-txt {
    font-size: 24px;
    padding-left: 28px;
  }
  
  /*$Loader Multiple circle
  ========================================================================== */
  
  .loader-circles {
    border-radius: 50px;
    border: 3px solid transparent;
    border-top-color: #fff;
    @include animation(spin 1s linear infinite);
    &:before,
    &:after {
      content: "";
      @include absPosition(5px, 5px, 5px, 5px);
      border-radius: 50px;
      border: 3px solid transparent;
      border-top-color: $color;
      @include opacity(0.8);
      @include animation(spinreverse 5s linear infinite);
    }
    &:before {
      top: 12px;
      left: 12px;
      bottom: 12px;
      right: 12px;
      @include animation(spinreverse 10s linear infinite);
    }
  }
  
  /*$Loader Bars
  ========================================================================== */
  
  .loader-bars:before,
  .loader-bars:after,
  .loader-bars span {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    @include size(10px, 30px);
    background-color: $color;
    @include animation(grow 1.5s linear infinite);
  }
  
  .loader-bars:after {
    left: 15px;
    @include animation(grow 1.5s linear -0.5s infinite);
  }
  
  .loader-bars span {
    left: 30px;
    @include animation(grow 1.5s linear -1s infinite);
  }
  
  @-webkit-keyframes grow {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      opacity: 0;
    }
    50% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      opacity: 0;
    }
  }
  
  @keyframes grow {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      opacity: 0;
    }
    50% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spinreverse {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  
  @keyframes spinreverse {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  </style>
  