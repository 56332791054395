<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogTouchpoint"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5" v-if="!editView">Add touchpoint</span>
        </v-card-title>
        <v-card-text style="max-height:450px; overflow-y:auto;">
          <v-container class="touchpointAdd">
            <v-row class="mt-2">
              <v-col cols="12" class="px-2 text-center">
                <input v-if="!imageData"
                  id="upload"
                  class="file-upload__input"
                  type="file" accept=".xlsx, .xls, .csv" name="file-upload"
                  @input="previewImage"
                >
                <p v-if="imageData" class="fileName">{{company.name}} <v-icon @click="changeLogo()">mdi-window-close</v-icon></p>
              </v-col>
              <v-col cols="12" v-if="errorList.length > 0 || Object.keys(errorList).length > 0">
                <p v-for="(e, i) in errorList" :key="i">
                  <strong style="text-transform: capitalize;">{{i}}:</strong> {{e}}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey"
            text
            @click="dialogTouchpoint = false;languageConfigured = 'en';changeLogo();"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-select
          style="max-width: 200px;margin-right:20px;"
          :items="languageItem"
          v-model="languageConfigured"
          item-text="text"
          item-value="value"
          label="Language"
          hide-details
          dense
          outlined
        ></v-select>
          <v-btn :loading="loading"
            color="#050550"  style="color: white;"
            @click="addTouchpoints()"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import { mapState } from 'vuex';
  export default {
    components: {
      axios
    },
    data: () => ({
      dialog: false,
      loading: false,
      dialogTouchpoint: false,
      editView: false,
      imageData: '',
      errorList: '',
      company: {},
      languageConfigured: 'en',
      languageItem: [
        { text: 'English', value: 'en' },
        { text: 'Malay & English', value: 'ms' },
      ],
    }),
computed: {
    ...mapState({
      snackbar: state => state.snackbar,
      user: state => state.user
    })
  },
    methods: {
      changeLogo () {
        this.imageData = '';
        this.errorList = '';
      },
      previewImage(event) {
        let input = event.target;
        if (input.files && input.files[0]) {
          let reader = new FileReader();
          reader.onload = (e) => {
            if (input.files[0].size < 2000000) {
              this.imageData = e.target.result;
              this.company.company_logo = input.files[0];
              this.company.name = input.files[0].name;
            } else {
              this.$store.dispatch('updateSnackbar', {
                color: 'error',
                show: true,
                text: 'Image size cannot be more then 2MB!'
              });
            }
          }
          reader.readAsDataURL(input.files[0]);
        }
      },
      addTouchpoints () {
        this.loading = true;
        const formData = new FormData();
        formData.append('milestone_config', this.company.company_logo);
        if (this.languageConfigured === 'ms') {
          formData.append('language_configured', 'ms,en');
        }
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.post(`${process.env.VUE_APP_API_URL_FB}company/create_milestone/${this.$route.query.cid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
        }).then((response) => {
          this.loading = false;
          if (response && response.data) {
            if (response.data.error && response.data.error.length > 0) {
              this.errorList = response.data.error;
            } else if (response.data.error && response.data.error.length > 0) {
              this.errorList = response.data.error_list;
            }
            if (response.data.milestones && response.data.milestones.length > 0) {
              this.dialogTouchpoint = false;
              this.$parent.companyTouchpoints = response.data.milestones;
              this.$parent.getSatges(this.$route.query.cid);
              this.languageConfigured = 'en';
            }
            if (response.data.success && response.data.success === true) {
              this.dialogTouchpoint = false;
              this.changeLogo ();
              this.$store.dispatch('updateSnackbar', {
                color: 'success',
                show: true,
                text: response.data.message
              });
            } 
          }
        }).catch((err) => {
          this.loading = false;
          console.log(err.response);
          if (err.response && err.response.data) {
            this.errorList = err.response.data.error;
          }
          if (err.response.status && err.response.status === 429 && err.response.data) {
            this.config.savingStage = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
    },

    mounted: {
      opendialog () {
        this.dialog = true;
      }
    }
  }
</script>

<style lang="scss">
.fileName {
    white-space: pre-wrap;
    vertical-align: middle;
    text-align: center;
    font-size: 1em;
    line-height: 1.5;
    display: inline-block;
    background: #f1f1f1;
    width:100%;
    padding: 45px 34px;
    font-family: Helvetica, Arial, sans-serif;
}
.touchpointAdd {
    input[type=file] {
        width:100%;
    }
    input[type=file]:before {
    content: 'Drag and drop or Click \A to add touchpoints.';
    white-space: pre-wrap;
    vertical-align: middle;
    text-align: center;
    font-size: 1em;
    line-height: 1.5;
    display: inline-block;
    background: #f1f1f1;
    width:100%;
    padding: 45px 34px;
    font-family: Helvetica, Arial, sans-serif;
    }

    input[type=file]::-webkit-file-upload-button {
    visibility: hidden;
    }
}

</style>