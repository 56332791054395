<template>
  <div class="text-center pt-0 onlyThis pa-4" style="background: #e8edf2 none repeat scroll 0 0; padding: 25px 50px 50px 50px !important;">
    <div class="headingprofile">
      <v-row class="w-100 ma-0">
        <v-col cols="12">
          <v-row class="w-100 ma-0">
            <v-col @click="changeTab('')" style="cursor: pointer;" cols="1" class="pa-0">
              <span :class="$route.name === 'dashboard' ? 'activeTab' : ''" style="padding: 0 0 5px">
                Dashboard
              </span>
            </v-col>
            <v-col style="cursor: pointer;" cols="4" class="pa-0">
              <span @click="changeTab('company-list')" :class="$route.name === 'companyList' ? 'activeTab' : ''" style="padding: 0 0 5px">
                Company list
              </span>
              <span><v-icon class="mr-0">mdi-chevron-right</v-icon> <strong style="padding: 0 0 5px" :class="$route.name === 'companyDetails' ? 'activeTab' : ''">{{companyData.company_details.company_name}}</strong></span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <h2 style="font-weight:300; font-size:30px;">Hi, Welcome back</h2>
          <p style="font-weight:300; font-size:16px;">Amara's back-end administration panel.</p>
        </v-col>
        <v-col cols="6">
        </v-col>
      </v-row>
    </div>
    <v-toolbar :class="(this.tabs === 'c_touchpoints' || this.tabs === 'c_summary') ? 'Toolbarext' : ''" flat style="height:60px !important; border-radius: 10px 10px 0 0;    padding: 11px 0 0 50px;">
      <template v-slot:extension>
        <v-tabs v-model="tabs">
          <v-tabs-slider style="color: #050550 !important;"></v-tabs-slider>
          <v-tab
            href="#c_details"
            class="app--text"
          >Company details
          </v-tab>

          <v-tab @click="getAdmins($route.query.cid)"
            href="#c_admins"
            class="app--text"
          >Admins
          </v-tab>
          <v-tab @click="getSatges($route.query.cid)"
            href="#c_touchpoints"
            class="app--text"
          >Touchpoints
          </v-tab>
          <v-tab href="#c_summary"
            class="app--text"
          >Company summary
          </v-tab>
          <v-tab @click="getDrivers()" href="#c_drivers"
            class="app--text"
          >Driver framework
          </v-tab>
          <v-tab @click="getAuditLog()" href="#c_auditlogs"
            class="app--text"
          >Audit logs
          </v-tab>
          <v-tab @click="getAuditLog()" href="#c_reports"
            class="app--text"
          >Reports
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <div style="z-index:2; position: absolute;right: 5%;top: 190px;;">
      <v-btn class="mr-2" v-if="tabs === 'c_details'" style="z-index:99999; color: white;" @click="editDetails(companyData);" color="#050550">
        <v-icon class="mr-2">mdi-square-edit-outline</v-icon> Edit
      </v-btn>
      <v-btn class="mr-2" v-if="tabs === 'c_admins'" style="z-index:99999; color: white;" @click="openAdminModal()" color="#050550">
        <v-icon class="mr-2">mdi-plus</v-icon> Add admin
      </v-btn>
      <!-- <v-tooltip bottom v-if="tabs === 'c_touchpoints'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="text-right mr-2" style="z-index:99999; color: #050550;"
            depressed @click="openSortingModal()"
            color="#050550" text icon
          >
            <v-icon class="mr-2">mdi-sort</v-icon>
          </v-btn>
        </template>
        <span>Sort list</span>
      </v-tooltip> -->
      <v-btn v-if="tabs === 'c_touchpoints'" class="text-right mr-2" style="z-index:99999; color: white;"
        depressed @click="openTouchpointModal()"
        color="#050550" 
      >
        <v-icon class="mr-2">mdi-plus</v-icon> Add touchpoint
      </v-btn>
      <v-tooltip bottom v-if="tabs === 'c_summary'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed text icon v-bind="attrs" v-on="on" v-if="tabs === 'c_summary'" class="mr-2" style="z-index:99999;" @click="sendReminder(companyData)" color="#050550">
            <v-icon class="">mdi-bell-ring-outline</v-icon>
          </v-btn>
        </template>
        <span>
          Trigger reminders</span>
      </v-tooltip>

    </div>
    <v-tabs-items style="border-radius:  0 0 10px 10px; min-height: 460px;" v-model="tabs">
      <v-tab-item v-if="companyData"
        :value="'c_details'"
      >
        <v-card flat  style="box-shadow:none; padding: 0 60px 25px;">
          <v-row class="w-100 ma-0 pt-4">
            <h3>Basic details</h3>
            <v-col cols="12" class="pl-0 pt-0 my-4" style="background: #f8f8f8; border-radius:10px;">
              <v-row class="w-100 ma-0 text-left" style="    align-items: center;">
                <v-col cols="2">
                  <img v-if="companyData.company_details && companyData.company_details.company_logo" style="width:80%;" alt="image"  :src="companyData.company_details.company_logo">
                  <img v-else style="width:80%;"  src="../assets/noLogo.png" alt="image">
                </v-col>
                <v-col cols="3" style="    font-size: 25px;font-weight: 500;">
                  {{companyData.company_details.company_name}}
                  <v-tooltip bottom v-if="companyData.plan_details.account_type === 'Paid' || companyData.plan_details.account_type === 'paid'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" style="color:green; border: 1px solid; padding: 2px;border-radius: 100%;" >
                        mdi-currency-usd
                      </v-icon>
                    </template>
                    <span>Paid</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs"
                        v-on="on" style="border: 1px solid;padding: 2px;border-radius: 100%;" >
                        mdi-currency-usd-off
                      </v-icon>
                    </template>
                    <span>Pilot</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="7">
                  <v-radio-group disabled
                    v-model="companyData.company_details.is_active"
                    row
                  >
                    <v-radio color="#050550"
                      label="Active"
                      :value="true"
                    ></v-radio>
                    <v-radio color="#050550"
                      label="Inactive"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px; ">
                    <p class="mb-0" style="font-size: 80%;">Brand name</p>
                    <span style="font-weight:bold;">{{companyData.company_details.brand_name}}</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="   padding:10px; ">
                    <p class="mb-0" style="font-size: 80%;">Industry type</p>
                    <span style="font-weight:bold;">{{companyData.company_details.company_type}}</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px; ">
                    <p class="mb-0" style="font-size: 80%;">Go live date</p>
                    <span style="font-weight:bold;" v-if="companyData.company_details.activatedAt">{{formatDate1(companyData.company_details.activatedAt)}}</span>
                    <span style="font-weight:bold;" v-else>--</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px; ">
                    <p class="mb-0" style="font-size: 80%;">Employee strength</p>
                    <span style="font-weight:bold;" v-if="companyData.company_details.employee_strength">{{companyData.company_details.employee_strength}}</span>
                    <span style="font-weight:bold;" v-else>--</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px; ">
                    <p class="mb-0" style="font-size: 80%;" >Users booked</p>
                    <span style="font-weight:bold;" v-if="companyData.company_details.user_booked">{{companyData.company_details.user_booked}}</span>
                    <span style="font-weight:bold;" v-else>--</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px; ">
                    <p class="mb-0" style="font-size: 80%;">Address</p>
                    <span style="font-weight:bold;">{{companyData.company_details.company_address}}</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="   padding:10px; ">
                    <p class="mb-0" style="font-size: 80%;">Country</p>
                    <span style="font-weight:bold;">{{companyData.company_details.country}}</span>
                  </div>
                </v-col>
                <v-col cols="4">
                </v-col>
              </v-row>
              <p class="mb-0 mt-4 text-left pl-3" style="font-weight: 600;">SPOC details</p>
              <v-row class="w-100 ma-0 text-left" style="    align-items: center;">
                <v-col cols="4">
                  <div style="padding:10px; ">
                    <p class="mb-0" style="font-size: 80%;">SPOC name</p>
                    <span style="font-weight:bold;">{{companyData.company_details.spoc_name}}</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px; ">
                    <p class="mb-0" style="font-size: 80%;">SPOC email</p>
                    <span style="font-weight:bold;">{{companyData.company_details.spoc_email}}</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px; ">
                    <p class="mb-0" style="font-size: 80%;">SPOC phone</p>
                    <span style="font-weight:bold;">{{companyData.company_details.spoc_phone}}</span>
                  </div>
                </v-col>
              </v-row>
              <p class="mb-0 mt-4 text-left pl-3" style="font-weight: 600;">Social URLs</p>
              <v-row class="w-100 ma-0 text-left" style="align-items: center;">
                <v-col cols="4">
                  <div style="padding:10px; ">
                  <p class="mb-0" style="font-size: 80%;">Facebook url</p>
                  <span style="font-weight:bold;" v-if="companyData.company_details.facebook_link">{{companyData.company_details.facebook_link}}</span>
                  <span v-else>--</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px; ">
                  <p class="mb-0" style="font-size: 80%;">Glassdoor url</p>
                  <span style="font-weight:bold;" v-if="companyData.company_details.glassdoor_link">{{companyData.company_details.glassdoor_link}}</span>
                  <span v-else>--</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px; ">
                  <p class="mb-0" style="font-size: 80%;">Linkedin url</p>
                  <span style="font-weight:bold;" v-if="companyData.company_details.linkedin_link">{{companyData.company_details.linkedin_link}}</span>
                  <span v-else>--</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px; ">
                  <p class="mb-0" style="font-size: 80%;">jobStreet url</p>
                  <span style="font-weight:bold;" v-if="companyData.company_details.jobStreet_link">{{companyData.company_details.jobStreet_link}}</span>
                  <span v-else>--</span>
                  </div>
                </v-col>
              </v-row>
              <p class="mb-0 mt-4 text-left pl-3" style="font-weight: 600;">Avatars</p><br>
              <v-row class="w-100 ma-0">
                <v-col cols="12" v-if="companyData && companyData.company_details && companyData.company_details.companies_avatar">
                  <v-row class="w-100 ma-0">
                    <v-col cols="3" v-for="(a, i) in companyData.company_details.companies_avatar" :key="i">
                      <div class="avatarBox" :style="companyData.company_details.company_avatar == i ? `border-color:#050550; border-width: 2px; position:relative;` : ''" >
                        <img style="margin-top:10px; padding: 0px 30%; width:100%;" :src="a" alt="image">
                        <v-icon v-if="companyData.company_details.company_avatar == i" class="mb-0" style="position: absolute; top: 0; right:5px; text-align: center; font-size:40px !important; color:#050550;">mdi-check-all</v-icon>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <h3>Plan details</h3>
            <v-col cols="12" class="my-4" style="background: #f8f8f8; border-radius:10px;">
              <v-row class="w-100 ma-0 text-left">
                <v-col cols="12" class="pb-0 mb-0">
                  <div style="padding:10px;" class="">
                    <span style="font-weight:bold; font-size: 20px;">{{companyData.plan_details.title}}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="w-100 ma-0 text-left">
                <v-col cols="4">
                  <div style="padding:10px;" class="pt-0">
                    <p class="mb-0" style="font-size: 80%;">Adhoc active</p>
                    <span style="font-weight:bold" v-if="companyData.plan_details.adhoc_active">Yes</span>
                  <span style="font-weight:bold" v-else>No</span>
                  </div>
                </v-col>
                <v-col cols="4" v-if="companyData.plan_details.adhoc_active">
                  <div style="padding:10px;" class="pt-0">
                    <p class="mb-0" style="font-size: 80%;">Adhoc limit</p>
                    <span style="font-weight:bold;">{{companyData.plan_details.adhoc_limit}}</span>
                  </div>
                </v-col>
                <v-col cols="4" v-if="companyData.plan_details.adhoc_active">
                  <div style="padding:10px;" class="pt-0">
                    <p class="mb-0" style="font-size: 80%;">Adhoc remaining limit</p>
                    <span style="font-weight:bold;" v-if="companyData.plan_details.adhoc_limit_remaining">{{companyData.plan_details.adhoc_limit_remaining}}</span>
                    <span style="font-weight:bold;" v-else>0</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="w-100 ma-0 text-left">
                <v-col cols="4">
                  <div style="padding:10px;" class="pt-0">
                    <p class="mb-0" style="font-size: 80%;">Touchpoints active</p>
                    <span style="font-weight:bold" v-if="companyData.plan_details.milestone_active">Yes</span>
                  <span style="font-weight:bold" v-else>No</span>
                  </div>
                </v-col>
                <v-col cols="4" v-if="companyData.plan_details.adhoc_active">
                  <div style="padding:10px;" class="pt-0">
                    <p class="mb-0" style="font-size: 80%;">Touchpoints limit</p>
                    <span style="font-weight:bold">{{companyData.plan_details.milestone_limit}}</span>
                  </div>
                </v-col>
                <v-col cols="4" v-if="companyData.plan_details.milestone_active">
                  <div style="padding:10px;" class="pt-0">
                    <p class="mb-0" style="font-size: 80%;">Touchpoint remaining limit</p>
                    <span style="font-weight:bold;" v-if="companyData.plan_details.milestone_limit_remaining">{{companyData.plan_details.milestone_limit_remaining}}</span>
                    <span style="font-weight:bold;" v-else>0</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="w-100 ma-0 text-left">
                <v-col cols="4">
                  <div style="padding:10px;" class="pt-0">
                    <p class="mb-0" style="font-size: 80%;">Exit active</p>
                    <span style="font-weight:bold" v-if="companyData.plan_details.exit_active">Yes</span>
                  <span style="font-weight:bold" v-else>No</span>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px;" class="pt-0">
                    <p class="mb-0" style="font-size: 80%;">Employee wellbeing active</p>
                    <span style="font-weight:bold" v-if="companyData.plan_details.employee_wellbeing_active">Yes</span>
                  <span style="font-weight:bold" v-else>No</span>
                  </div>
                </v-col>
                </v-row>
            </v-col>
            <h3 >Company settings</h3>
            <v-col cols="12" class="my-4" style="background: #f8f8f8; border-radius:10px;">
              <v-row class="w-100 ma-0 text-left">
                <v-col cols="12">
                  <div style="padding:10px;  min-height:90px;">
                    <p class="mb-2" style="font-size: 80%;">Whitelisted email domains</p>
                    <div v-if="companyData.company_settings  && companyData.company_settings.whitelisted_email_domains && companyData.company_settings.whitelisted_email_domains.length > 0">
                      <v-chip class="mr-2" v-for="(domain, d) in companyData.company_settings.whitelisted_email_domains" :key="d">
                        {{domain}}
                      </v-chip>
                    </div>
                    <div v-else>
                      No whitelisted email domains found
                    </div>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px;  min-height:90px;">
                    <p class="mb-0" style="font-size: 80%;">Co-branding</p>
                    <v-radio-group disabled
                      v-model="companyData.company_details.chat_cobranding_enabled"
                      row
                    >
                      <v-radio color="#050550"
                        label="Enable"
                        :value="true"
                      ></v-radio>
                      <v-radio color="#050550"
                        label="Disable"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px;  min-height:90px;">
                    <p class="mb-0" style="font-size: 80%;">Resolution summary</p>
                  <v-radio-group disabled
                  v-model="companyData.company_settings.enable_action_summary"
                  row
                >
                  <v-radio color="#050550"
                    label="Optional"
                    value="1"
                  ></v-radio>
                  <v-radio color="#050550"
                    label="Mandatory"
                    value="2"
                  ></v-radio>
                  <v-radio color="#050550"
                    label="Disable"
                    value="0"
                  ></v-radio>
                </v-radio-group>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px;  min-height:90px;">
                    <p class="mb-0" style="font-size: 80%;">Touchpoint edit</p>
               <v-radio-group disabled
                  v-model="companyData.company_details.allow_stage_edit"
                  row
                >
                  <v-radio color="#050550"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#050550"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                  </div>
                </v-col>

                <v-col cols="4">
                  <div style="padding:10px;  min-height:90px;">
                    <p class="mb-0" style="font-size: 80%;">Employer Branding</p>
               <v-radio-group disabled
                  v-model="companyData.company_settings.enable_brand_promotion_feedback"
                  row
                >
                  <v-radio color="#050550"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#050550"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                  </div>
                </v-col>
                <v-col cols="4" v-if="companyData.company_settings.enable_brand_promotion_feedback">
                  <div style="padding:10px;  min-height:107px;">
                    <p class="mb-0" style="font-size: 80%;">Select employer branding</p>
                    <v-autocomplete :disabled="!editView"  v-if="companyData.company_settings.enable_brand_promotion_feedback"
                        :items="LinkItem"
                        v-model="companyData.company_settings.brand_promotion_feedback_field"
                        item-text="name" hide-details
                        placeholder="Select employer branding"
                        outlined dense
                      ></v-autocomplete>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px;  min-height:90px;">
                    <p class="mb-0" style="font-size: 80%;">Disengaged auto assign</p>
                    <v-radio-group disabled
                      v-model="companyData.company_settings.disengaged_auto_assign"
                      row
                    >
                      <v-radio color="#050550"
                        label="Enable"
                        :value="true"
                      ></v-radio>
                      <v-radio color="#050550"
                        label="Disable"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
                <v-col cols="4" v-if="companyData.company_settings.disengaged_auto_assign">
                  <div style="padding:10px;  min-height:107px;">
                    <p class="mb-0" style="font-size: 80%;">Disengaged auto assignd to</p>
                    <v-autocomplete style="margin-top:6px;" :disabled="!editView"  v-if="companyData.company_settings.disengaged_auto_assign"
                        :items="autoAssignItem" hide-details
                        v-model="companyData.company_settings.disengaged_reference_field"
                        item-text="name"
                        placeholder="Select employer branding"
                        outlined dense
                      ></v-autocomplete>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div style="padding:10px;  min-height:90px;">
                    <p class="mb-0" style="font-size: 80%;">Fence-sitters auto assign</p>
                    <v-radio-group disabled
                      v-model="companyData.company_settings.passively_engaged_auto_assign"
                      row
                    >
                      <v-radio color="#050550"
                        label="Enable"
                        :value="true"
                      ></v-radio>
                      <v-radio color="#050550"
                        label="Disable"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
                <v-col cols="4" v-if="companyData.company_settings.passively_engaged_auto_assign">
                  <div style="padding:10px;  min-height:107px;">
                    <p class="mb-0" style="font-size: 80%;">Fence-sitters auto assignd to</p>
                    <v-autocomplete style="margin-top:6px;" :disabled="!editView"  v-if="companyData.company_settings.passively_engaged_auto_assign"
                        :items="autoAssignItem" hide-details
                        v-model="companyData.company_settings.passively_reference_field"
                        item-text="name"
                        placeholder="Select employer branding"
                        outlined dense
                      ></v-autocomplete>
                  </div>
                </v-col>
              <v-col cols="4">
                  <div style="padding:10px;  min-height:90px;">
                    <p class="mb-0" style="font-size: 80%;">Auto acknowledge</p>
                    <v-radio-group disabled
                      v-model="companyData.company_settings.auto_acknowledge"
                      row
                    >
                      <v-radio color="#050550"
                        label="Enable"
                        :value="true"
                      ></v-radio>
                      <v-radio color="#050550"
                        label="Disable"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              <v-col cols="4">
                <div style="padding:10px;  min-height:90px;">
                    <p class="mb-0" style="font-size: 80%;">Alert word</p>
            <v-radio-group disabled
                  v-model="companyData.company_settings.chat_alert_word_notification"
                  row
                >
                  <v-radio color="#050550"
                    label="Enable"
                    :value="true"
                  ></v-radio>
                  <v-radio color="#050550"
                    label="Disable"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                  </div>
              </v-col>
              <v-col cols="4">
                <div style="padding:10px;  min-height:90px;">
                    <p class="mb-0" style="font-size: 80%;">Brand color</p>
                    <v-text-field readonly disabled dense outlined placeholder="Brand color" v-model="companyData.company_settings.chat_color_hex">
                    </v-text-field>
                  </div>
              </v-col>
              <v-col cols="12" v-if="companyData.company_settings.chat_alert_word_notification">
                <div style="padding:10px;  min-height:90px;">
                  <p class="mb-0" style="font-size: 80%;">Selected alert words</p>
                    <v-chip v-for="(w, i) in companyData.company_settings.chat_alert_words" :key="i"
                    color="#050550" 
                    class="text-capitalize mr-2 mt-2"
                    outlined
                    >
                    <strong class="text-capitalize">{{ w }}</strong>
                    </v-chip>
                </div>
              </v-col>
              </v-row>
            </v-col>
            
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item
        :value="'c_admins'"
      >
        <v-card class="py-4"  style="box-shadow:none;padding: 0 60px 25px;">
          <div v-if="companyAdmins.length < 1" style="border:1px solid #d7d7d7; padding:10%">
            <img style="width:15%;" src="../assets/amara_avatar.png" alt="image"><br>
            <p style="margin-bottom:10px;    color: #9e9e9e !important; font-weight:400;    font-size: 26px;">You haven’t added any admin yet. Please add admin!</p>
            <v-btn class="text-right" style="color: white;"
              depressed @click="openAdminModal()"
              color="#050550"
            >
              Add admin
            </v-btn>  
          </div>
          <div v-if="companyAdmins.length > 0">
            <v-row class="w-100 ma-0 text-left">
              <v-col cols="1" style="padding-left:25px;">
                S/N
              </v-col>
              <v-col cols="3" class="px-0">
                Name
              </v-col>
              <v-col cols="2">
                Department
              </v-col>
              <v-col cols="2">
                Joining date
              </v-col>
              <v-col cols="1">
                Gender
              </v-col>
              <v-col cols="1">
                Location
              </v-col>
              <v-col cols="2" class="px-0">
                Actions
              </v-col>
            </v-row>
            <v-row class="w-100 ma-0 text-left" v-for="(s, i) in companyAdmins"  :key="i" style="background-color: var(--bg)!important;
              border-radius: 0.375rem!important;
              position: relative;
              border: none!important; border-left: 4px solid #050550;
              box-shadow: 0 4px 16px 11px rgba(0,0,0,.06),0 4px 8px rgba(0,0,0,.1)!important; margin-bottom: 10px !important;">
              <v-col cols="1" style="align-self: center;padding-left:25px;">
                {{i+1}}
              </v-col>
              <v-col cols="3" class="px-0" style="align-self: center;">
                {{s.display_name}}<br>
                {{s.email}}
              </v-col>
              <v-col cols="2" style="align-self: center;">
                {{s.department}}
              </v-col>
              <v-col cols="2" style="align-self: center;">
                {{ moment(s.joining_date).format('DD MMM YYYY') }}
              </v-col>
              <v-col cols="1" style="align-self: center;">
                <span v-if="s.gender">{{s.gender}}</span>
                <span v-else>--</span>
              </v-col>
              <v-col cols="1" style="align-self: center;" @click="getSatgeDetails(s.stage_details.survey, s.stage_details.id)">
                {{s.location}}
              </v-col>
              <v-col cols="2" class="px-0" style="align-self: center;">
                <v-btn icon  @click="openAdminModal(s)">
                  <v-icon>mdi-lead-pencil</v-icon>
                </v-btn>
                <v-btn v-if="s.user_id" text outlined @click="resendEmail(s.user_id)">
                  Resend email
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item
        :value="'c_touchpoints'"
      >
        <v-card style="box-shadow:none;padding: 0 60px 25px;">
          <div v-if="companyTouchpoints.length < 1" style="border:1px solid #d7d7d7; padding:10%">
            <img style="width:15%;" src="../assets/amara_avatar.png" alt="image"><br>
            <p style="margin-bottom:10px;    color: #9e9e9e !important; font-weight:400;    font-size: 26px;">You haven’t added any touchpoint yet. Please add touchpoint!</p>
            <v-btn class="text-right" style="color: white;"
              depressed :loading="loading" @click="openTouchpointModal()"
              color="#050550" 
            >
              Add touchpoint
            </v-btn>  
          </div>
          <div v-if="companyTouchpoints.length > 0">
            <v-row class="mt-3 mb-3">
              <v-col cols="6" class="text-left pl-5">
                <v-btn
                  color="#383535"
                  style="width: 240px;"
                  outlined
                  @click="bulkActionTouchpoint = [];bulkChatUser = '';selectAll = false;reportDialog = true;getstagesBulk($route.query.cid);"
                ><v-icon class="mr-2">mdi mdi-pencil-box-multiple-outline</v-icon>
                  Bulk action
                </v-btn>
                <v-btn class="ml-4" style="color: #383535;width: 240px;"
                  depressed @click="openSortingModal()"
                  color="#383535" outlined
                >
                  <v-icon class="mr-2">mdi-sort</v-icon>
                  Sort list
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="w-100 ma-0 text-left">
              <v-col cols="1">
                S/N
              </v-col>
              <v-col cols="2">
                Touchpoint
              </v-col>
              <v-col cols="2">
                Trigger time
              </v-col>
              <v-col cols="3">
                Chat for user
              </v-col>
              <v-col cols="1">
                Status
              </v-col>
              <v-col cols="3">
                Action
              </v-col>
            </v-row>
            <v-row class="w-100 ma-0 text-left" v-for="(s, i) in companyTouchpoints"  :key="i">
              <v-col cols="1">
                {{((pagination.page-1)*20)+i+1}}
              </v-col>
              <v-col cols="2" v-if="s.stage_details">
                {{s.stage_details.title}}
              </v-col>
              <v-col cols="2" v-if="s.stage_details">
                {{s.stage_details.trigger_time_ym_formatted}}
              </v-col>
              <v-col cols="3" v-if="s.stage_details">
                <span v-if="s.stage_details.chatFromUser__name">{{s.stage_details.chatFromUser__name}}</span>
                <span v-else>--</span>
              </v-col>
              <v-col cols="1" v-if="s.stage_details">
                <v-chip class="text-capitalize">{{s.stage_details.status}}</v-chip>
              </v-col>
              <v-col cols="3" v-if="s.stage_details" style="align-self:center">
                <v-btn small v-if="s.stage_details.status === 'live'" outlined @click="deactivateStage(s.stage_details.id)" style="color: rgb(5, 5, 80) !important;width:120px" color="#050550">
                  Deactivate
                </v-btn>
                <v-btn v-if="s.stage_details.status === 'draft'" small outlined @click="getSatgeDetails(s.stage_details.survey, s.stage_details.id, s.stage_details)" style="color: rgb(5, 5, 80) !important;width:120px" color="#050550">
                  Activate
                </v-btn>
                <v-tooltip bottom v-if="s.stage_details">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small text icon style="" class="ml-4"  @click="getSatgeDetails(s.stage_details.survey, s.stage_details.id, s.stage_details)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom v-if="s.stage_details && s.stage_details.status && s.stage_details.status === 'draft'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small text icon style="" class="ml-4"  @click="deleteStage(s.stage_details.id)">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
          <div class="d-flex" v-if="companyTouchpoints.length > 0">
        <v-flex xs1></v-flex>
        <v-flex class="pr-4 mb-0 ml-3" xs10 style="text-align:center">
          <v-pagination
            class="adhoc--text"
            color="primary"
            v-model="pagination.page"
            :length="pagination.length"
            :total-visible="7"
            @input="getSatges($route.query.cid)"
            circle
          ></v-pagination>
        </v-flex>
        <v-flex xs1></v-flex>
      </div>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="companyData"
        :value="'c_summary'"
      >
        <company-summary :company-data="companyData" ref="companysummary" class="" />
      </v-tab-item>
      <v-tab-item v-if="companyData"
        :value="'c_drivers'"
      >
        <v-card flat  style="box-shadow:none; padding: 0 60px 25px;">
          <v-row class="w-100 ma-0">
            <v-col cols="4" class="text-left" v-for="(driver, i) in driverList" :key="i">
              <v-card style="height:100%; border: 1px solid #cecece; border-radius: 6px;; box-shadow: none; position: relative; padding-bottom: 60px;"
                class="mx-auto px-4 pt-4"
              >
                <h3>{{i.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}}</h3>
                <v-list-item style="cursor: default; padding-bottom: 0; min-height:30px;" v-for="(subdri, j) in driver" :key="j">
                  <v-list-item-content class="pb-0">
                    <v-list-item-title><span style="">{{subdri.sub_driver.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}}</span>
                      <span style="float:right; cursor: pointer; padding: 0 5px;" @click.stop="deleteSubDriver(subdri)">
                        <v-icon>mdi-delete-outline</v-icon>
                      </span> 
                      <span style="float:right; cursor: pointer; padding: 0 5px" @click="editSubDriver(i, subdri)">
                        <v-icon>mdi-pencil</v-icon>
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <div style="text-align:center;padding-bottom: 10px; position: absolute; bottom: 0; width: 93%;">
                  <v-btn @click="addNewSubDriver(i)" outlined color="primary">Add new subdriver</v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item
        :value="'c_auditlogs'"
      >
        <v-card flat  style="box-shadow:none; min-height: 460px; max-height: 550px; overflow-y: auto; overflow-x: hidden; padding: 0 60px 25px;">
          <div style=" background: white; border-radius:10px; padding: 0px 0px;text-align:left;" >
          <v-row class="ma-0 w-100">
            <v-col cols="4">
              <p style="color: #878888;">Activities</p>
            </v-col>
            <v-col cols="4">
              <div style="float:right" class="right d-flex align-center" >
                <div class="pre-icon cursor-pointer mr-3 pt-1" @click="displayPre()">
                  <v-icon>mdi-arrow-left-drop-circle-outline</v-icon>
                </div>
                <div>{{getPreNumber}} - {{getNextNumber}} of {{totalCount}}</div>
                <div class="next-icon cursor-pointer ml-3 pt-1" @click="displayNext()">
                  <v-icon>mdi-arrow-right-drop-circle-outline</v-icon>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <div style="" class="infinite-wrapper">
                <v-timeline style="padding:20px 20px 0 0;"
                  align-top
                  dense
                >
                  <v-timeline-item
                    v-for="(audit, i) in auditLog"
                    :key="i"
                    :color="getColor(audit.type)"
                    :icon="getIcon(audit.type)"
                    fill-dot
                  >
                    <v-card
                      :color="getColor(audit.type)"
                      dark
                    >
                      <v-card-title style="font-size: 14px;padding: 0 10px; color: #263238;">
                        <v-row>
                          <v-col cols="10">
                            {{audit.type[0].toUpperCase() + audit.type.slice(1).split('_').join(' ')}}
                          </v-col>
                          <v-col cols="2">
                            <p class="mb-0"
                            v-if="audit.type !== 'user_login_failed' && audit.type !== 'user_logged_in' && audit.type !== 'user_logged_out' "
                            @click="viewLogDetails(audit)" style="font-size: 80%;text-decoration: underline;text-align: right;cursor:pointer;">View details</p>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="white pa-2 text-left text--primary">
                        <v-row>
                          <v-col cols="4">
                            {{ moment(audit.created_at).format('DD MMM YYYY hh:mm  a') }}
                          </v-col>
                          <v-col cols="8">
                            <p v-if="audit.type === 'user_logged_in'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has logged in successfully.</p>
                            <p v-if="audit.type === 'user_logged_out'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has logged out successfully.</p>
                            <p v-if="audit.type === 'report_download'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has downloaded the happiness index report.</p>
                            <p v-if="audit.type === 'admin_added'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has added admin.</p>
                            <p v-if="audit.type === 'plan_settings'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has changed plan details.</p>
                            <p v-if="audit.type === 'driver_updated'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has updated sub driver <strong v-if="audit && audit.data">{{audit.data}}</strong>.</p>
                            <p v-if="audit.type === 'driver_added'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has added a new sub driver <strong v-if="audit && audit.data">{{audit.data}}</strong>.</p>
                            <p v-if="audit.type === 'driver_deleted'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has deleted a sub driver <strong v-if="audit && audit.data">{{audit.data}}</strong>.</p>
                            <p v-if="audit.type === 'company_settings'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has changed company settings.</p>
                            <p v-if="audit.type === 'company_profile'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has changed company profile.</p>
                            <p v-if="audit.type === 'touchpoint_upload'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has uploaded the touchpoint.</p>
                            <p v-if="audit.type === 'touchpoint_deleted'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has deleted a touchpoint <strong v-if="audit && audit.data">{{audit.data}}</strong>.</p>
                            <p v-if="audit.type === 'touchpoint_updated'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has updated a touchpoint <strong v-if="audit && audit.data">{{audit.data}}</strong>.</p>
                            <p v-if="audit.type === 'template_updated'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has updated a template <strong v-if="audit && audit.data">{{audit.data}}</strong>.</p>
                            <p v-if="audit.type === 'company_added'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has added this company.</p>
                            <p v-if="audit.type === 'reminders_trigger'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span>  has trigger reminders.</p>
                            <p v-if="audit.type === 'touchpoint_index'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has change touchpoint index this company.</p>
                            <p v-if="audit.type === 'sub_driver_updated'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has updated sub driver <strong v-if="audit && audit.data">{{audit.data}}</strong>.</p>
                            <p v-if="audit.type === 'sub_driver_added'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has added a new sub driver <strong v-if="audit && audit.data">{{audit.data}}</strong>.</p>
                            <p v-if="audit.type === 'sub_driver_deleted'" class="mb-0"><span v-if="audit.created_by && audit.created_by.display_name">{{audit.created_by.display_name}}</span> has deleted a sub driver <strong v-if="audit && audit.data">{{audit.data}}</strong>.</p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
                <infinite-loading @infinite="infiniteHandler" spinner="waveDots" force-use-infinite-wrapper=".infinite-wrapper">
                  <span slot='no-more'>{{' '}}</span>
                  <span slot='no-results'>{{' '}}</span>
                </infinite-loading>
              </div>
            </v-col>
          </v-row>
        </div>
        </v-card>
      </v-tab-item>
      <v-tab-item
        :value="'c_reports'"
      >
        <v-card flat  style="box-shadow:none; min-height: 460px; max-height: 550px; overflow-y: auto; overflow-x: hidden; padding: 0 60px 25px;">
          <h3 class="mb-5" style="text-align: left;">Reports</h3>
          <div style=" background: #f1f1f1;border-radius:10px; padding: 0px 0px;text-align:left;min-height:500px;" >
            <v-row class="w-100 ma-0">
              <v-col class="pa-0" cols="4">
                <div style="border-radius: 6px; margin: 12px; background:#fff;" class="am-card-animation pa-2 text-center">
                  <p class="am-table-title" style="font-weight: 500; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="Happiness Index Report">Happiness Index Report</p>
                  <p style="min-height: 96px;" class="am-table-sub-title">This report consolidates the feedback ratings from employees who have completed chat interactions. It includes options to download all chats or the latest completed chats feedback details.</p>
                  <v-btn text elevation="0" class="am-table-title" @click="dialogOpen = true"><v-icon class="mr-2" style="font-size: 14px;">fa-regular fa-file-download</v-icon><span class="am-table-title" style="font-size: 12px !important;">Download</span> </v-btn>
                </div>
              </v-col>
              <v-col class="pa-0" cols="4">
                <div style="border-radius: 6px; margin: 12px; background:#fff;" class="am-card-animation pa-2 text-center">
                  <p class="am-table-title" style="font-weight: 500; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="Employee Policy Acknowledgement Report">Employee Policy Acknowledgement Report</p>
                  <p style="min-height: 96px;" class="am-table-sub-title">This report provides a detailed list of employees who have acknowledged and accepted our company's policies.</p>
                  <v-btn class="am-table-title" text elevation="0" @click="downloadRatingReports()"><v-icon class="mr-2" style="font-size: 14px;">fa-regular fa-file-download</v-icon><span class="am-table-title" style="font-size: 12px !important;">Download</span> </v-btn>
                </div>
              </v-col>
              <v-col class="pa-0" cols="4">
                <div style="border-radius: 6px; margin: 12px; background:#fff;" class="am-card-animation pa-2 text-center">
                  <p class="am-table-title" style="font-weight: 500; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="Dashboard Login Logs">Dashboard Login Logs</p>
                  <p style="min-height: 96px;" class="am-table-sub-title">This report provides a comprehensive overview of all dashboard admin login activities, including timestamps, IP addresses, and user identification. It is designed to facilitate security audits and track access patterns.</p>
                  <v-btn class="am-table-title" text elevation="0" @click="downloadLoginReports()"><v-icon class="mr-2" style="font-size: 14px;">fa-regular fa-file-download</v-icon><span class="am-table-title" style="font-size: 12px !important;">Download</span> </v-btn>
                </div>
              </v-col>
              <v-col class="pa-0" cols="4">
                <div style="border-radius: 6px; margin: 12px; background:#fff;" class="am-card-animation pa-2 text-center">
                  <p class="am-table-title" style="font-weight: 500; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="Dashboard Login Logs">Export Touchpoints</p>
                  <p style="min-height: 96px;" class="am-table-sub-title">This report provides a detailed mapping of Touchpoints including questions, postbacks, and rating metrics.</p>
                  <v-btn class="am-table-title" text elevation="0" @click="downloadTouchpointReports()"><v-icon class="mr-2" style="font-size: 14px;">fa-regular fa-file-download</v-icon><span class="am-table-title" style="font-size: 12px !important;">Download</span> </v-btn>
                </div>
              </v-col>
            </v-row>
        </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <addCompany ref="addcompany" class="" />
    <addTouchpoint ref="addtouchpoint" class="" />
    <stageDetails ref="stagedetails" class="" />
    <addAdmin ref="addadmin" class="" />
    <v-dialog
      v-model="dialogOpen"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Happiness index report
        </v-card-title>
        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="12" class="text-center pt-0">
              <v-radio-group v-model="reportType">
                <v-radio
                  color="primary"
                  v-for="(t, ti) in reportItem"
                  :key="ti"
                  :label="t.label"
                  :value="t.value"
                ></v-radio>
                </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="am-table-title mx-1" text @click="reportType = '';dialogOpen = false;">Cancel</v-btn>
          <v-spacer></v-spacer>
            <v-btn :loading="reportloading" :disabled="!reportType" class="am-table-title mx-1" style="color:white" color="#050550" @click="downloadRating(reportType)">Download</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="subdriverDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          <span v-if="selectedSubDriver">Update</span>
          <span v-else>Add</span>
        </v-card-title>

        <v-card-text>
          <p v-if="selectedSubDriver">Update <strong style="text-transform:lowercase">{{selectedSubDriver.sub_driver}}</strong> in <strong><span style="text-transform:lowercase">{{selectedDriver.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}}</span></strong></p>
          <p v-else>Add new sub driver in <strong><span style="text-transform:lowercase">&nbsp;{{selectedDriver.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}}</span></strong></p>
          <label>Sub driver name</label>
          <v-text-field dense outlined placeholder="Please enter sub driver name" v-model="subdriverName">
          </v-text-field>
          <label>Description</label>
          <v-textarea dense outlined placeholder="Please enter description" v-model="subDriverDescription">
          </v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color=""
            text
            @click="closeDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="selectedSubDriver"
            color="primary"
            @click="updateSubdriver()"
          >
            Update
          </v-btn>
          <v-btn v-else
            color="primary"
            @click="saveSubdriver()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="logDetails"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Audit details
        </v-card-title>
        <v-card-text style="max-height: 500px; overflow-y:auto;">
          <v-row v-if="logData">
              <v-col cols="3">
                <strong>Date : </strong>
              </v-col>
              <v-col cols="9">
                <p class="mb-0">{{moment(logData.created_at).format('DD-MM-YYYY  HH:mm')}}</p>
              </v-col>
              <v-col cols="3">
                <strong>IP Address : </strong>
              </v-col>
              <v-col cols="9">
                <p class="mb-0" v-if="logData.extra_data">{{logData.extra_data.client_ip}}</p>
              </v-col>
              <v-col cols="3">
                <strong>User : </strong>
              </v-col>
              <v-col cols="9">
                <strong v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</strong>
                <strong v-else>--</strong><br>
                <p class="mb-0" v-if="logData.created_by && logData.created_by.email">{{logData.created_by.email}}</p>
                <p class="mb-0" v-else>--</p>
              </v-col>
              <v-col cols="3">
                <strong>Activity : </strong>
              </v-col>
              <v-col cols="9">
                <p v-if="logData.type === 'user_logged_in'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has logged in successfully.</p>
                <p v-if="logData.type === 'user_logged_out'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has logged out successfully.</p>
                <p class="mb-0" v-if="logData && logData.type">{{logData.type.split('_').join(' ')}}</p>
                <p v-if="logData.type === 'report_download'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has downloaded the report.</p>
                <p v-if="logData.type === 'admin_added'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has added admin.</p>
                <p v-if="logData.type === 'plan_settings'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has changed plan details.</p>
                <p v-if="logData.type === 'driver_updated'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has updated sub driver <strong v-if="logData && logData.data">{{logData.data}}</strong>.</p>
                <p v-if="logData.type === 'driver_added'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has added a new sub driver <strong v-if="logData && logData.data">{{logData.data}}</strong>.</p>
                <p v-if="logData.type === 'driver_deleted'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has deleted a sub driver <strong v-if="logData && logData.data">{{logData.data}}</strong>.</p>
                <p v-if="logData.type === 'company_settings'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has changed company settings.</p>
                <p v-if="logData.type === 'company_profile'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has changed company profile.</p>
                <p v-if="logData.type === 'touchpoint_upload'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has uploaded the touchpoint.</p>
                <p v-if="logData.type === 'touchpoint_deleted'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has deleted a touchpoint <strong v-if="logData && logData.data">{{logData.data}}</strong>.</p>
                <p v-if="logData.type === 'touchpoint_updated'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has updated a touchpoint <strong v-if="logData && logData.data">{{logData.data}}</strong>.</p>
                <p v-if="logData.type === 'company_added'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has added this company.</p>
                <p v-if="logData.type === 'reminders_trigger'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has trigger reminders.</p>
                <p v-if="logData.type === 'reminders_trigger'" class="mb-0">
                  <span v-if="logData.data && logData.data.inProgress">Inprgress: {{logData.data.inProgress}}</span>
                  <span v-else>Inprgress: 0</span>
                  <br>
                  <span v-if="logData.data && logData.data.initiated">Initiated: {{logData.data.initiated}}</span>
                  <span v-else>Initiated: 0</span>
                </p>
                <p v-if="logData.type === 'sub_driver_updated'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has updated sub driver <strong v-if="logData && logData.data">{{logData.data}}</strong>.</p>
                <p v-if="logData.type === 'sub_driver_added'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has added a new sub driver <strong v-if="logData && logData.data">{{logData.data}}</strong>.</p>
                <p v-if="logData.type === 'sub_driver_deleted'" class="mb-0"><span v-if="logData.created_by && logData.created_by.display_name">{{logData.created_by.display_name}}</span> has deleted a sub driver <strong v-if="logData && logData.data">{{logData.data}}</strong>.</p>
              </v-col>
              <v-col v-if="logData.type === 'touchpoint_upload' && logData.data" cols="3">
                <strong>Results :  </strong>
              </v-col>
              <v-col v-if="logData.type === 'touchpoint_upload' && logData.data" cols="9">
                Success : {{logData.data.success}}<br>
                Failure : {{logData.data.failure}} <br>
                Download file : <a @click="downloadTouchpointSheet(logData.data.file_url)">Download</a>
              </v-col>
              <v-col cols="3" v-if="logData.old_data">
                <strong>Old data : </strong>
              </v-col>
              <v-col cols="9" v-if="logData.old_data">
                <span v-for="(old, i) in logData.old_data" :key="i">
                  <span v-if="i !== 'activatedAt' && i !== 'company_avatar_id'" >
                    <span style="">
                      {{i.split('_').join(' ')}}
                    </span>: {{old}} <br>
                  </span>
                  <span v-if="i === 'activatedAt'"> Go live date : {{moment(old).format('DD-MM-YYYY  HH:mm')}}</span><br v-if="i === 'activatedAt'">
                  <span v-else-if="i === 'company_avatar_id'">
                    Old company avatar : <br>
                    <img style="width:13%;" :src="getAvatarAudit(old)" alt="image"><br>
                  </span>
                </span>
              </v-col>
              <v-col cols="3" v-if="logData.new_data">
                <strong>New data : </strong>
              </v-col>
              <v-col cols="9" v-if="logData.new_data">
                <span v-for="(newData, j) in logData.new_data" :key="j">
                  <span v-if="j !== 'activatedAt' && j !== 'company_avatar_id'">
                    {{j.split('_').join(' ')}}: {{newData}} <br>
                  </span>
                  <span v-else-if="j === 'activatedAt'"> Go live date : {{moment(newData).format('DD-MM-YYYY  HH:mm')}}</span><br v-if="j === 'activatedAt'">
                  <span v-else-if="j === 'company_avatar_id'">
                    New company avatar : <br>
                    <img style="width:13%;" :src="getAvatarAudit(newData)" alt="image"><br>
                  </span>
                </span>
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text
            @click="logDetails = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSorting"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          <v-row>
            <v-col cols="7">Touchpoint list</v-col>
            <v-col cols="5" class="text-right">
              <v-select
                v-model="sortBy"
                @change="sortByNew()"
                style="max-width:280px;"
                :items="items"
                item-text="name"
                placeholder="Sort by"
                label="Sort by"
                outlined
                dense
                attach
                hide-details=""
                clearable
              ></v-select>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px; overflow-y: auto;" v-if="loaderforAction">
          <amara-loader></amara-loader>
        </v-card-text>
        <v-card-text style="max-height: 500px; overflow-y: auto;" v-if="touchpointList && !loaderforAction">
          <draggable
            :list="touchpointList.data"
            class="list-group"
            ghost-class="ghost"
            :move="checkMove"
            @start="dragging = true"
            @end="dragging = false"
            :animation="100"
          >
            <v-flex
              v-for="(item, i) in touchpointList.data"
              style="width:33.33%; display: inline-block;"
              :key="i"
              pa-3
              p-2
            >
               <div style="border:1px solid #cecece; cursor:pointer; border-radius:6px;padding:10px;">
                <span class="mr-4" style="">{{i+1}}.</span>{{ item.stage_details.title}} <v-icon style="font-size:16px; float:right;" class="">mdi-arrow-all</v-icon></div> 
            </v-flex>
          </draggable>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            @click="dialogSorting = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="saveSortList()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="800px"
      v-model="reportDialog" persistent
      scrollable
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>
          Bulk action
        </v-card-title>
        <v-card-subtitle>
          Please select touchpoints on which you want to take bulk action.
        </v-card-subtitle>
        <v-card-text style="height: 500px; overflow-y: auto;" v-if="loaderforActionBulk">
          <amara-loader></amara-loader>
        </v-card-text>
        <v-card-text style="height: 500px;" v-if="!loaderforActionBulk">
          <v-row style="height: 80px; overflow-y:auto;">
            <v-col cols="6" class="text-left" style="align-self: center">
              <v-checkbox
              v-model="selectAll"
              label="Select all"
              style="width: 150px;margin:0px !important"
              hide-details
              @change="selectAll === true ? bulkActionTouchpoint = companyTouchpointsBulk : bulkActionTouchpoint = []"
            ></v-checkbox>
            </v-col>
        </v-row>
          <v-row no-gutters class="px-3"  style="height: 420px; overflow-y:auto;" v-if="companyTouchpointsBulk.length > 0">
            <v-col cols="4"
              v-for="(col, index) in companyTouchpointsBulk"
              :key="index"
            >
              <v-checkbox
                :id="index.toString()"
                hide-details
                v-model="bulkActionTouchpoint"
                :label="col.stage_details.title"
                :value="col"
              ></v-checkbox>
            </v-col>
          </v-row>
          <div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn outlined @click="reportDialog = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="bulkActionTouchpoint.length === 0" :loading="deleteStageL" color="primary" @click="deleteStage(null, 'bulk')">Bulk delete</v-btn>
          <v-btn :disabled="bulkActionTouchpoint.length === 0" :loading="deactivateStageL" color="primary" @click="deactivateStage(null, 'bulk')">Bulk deactivate</v-btn>
          <v-btn :disabled="bulkActionTouchpoint.length === 0" color="primary" @click="chatFromUserDialog = true">Bulk activate</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="400px"
      v-model="chatFromUserDialog" persistent
      scrollable
      transition="dialog-transition"
    >
    <v-card>
        <v-card-title>
          Chat from user
        </v-card-title>
        <v-card-subtitle>
          Please select chat from user.
        </v-card-subtitle>
        <v-card-text>
          <v-row class="mb-4">
            <v-col cols="12" class="text-center" style="align-self: center">
              <v-autocomplete :items="companyAdmins" style="width: 270px;"
              item-text="display_name"
              item-value="user_id"
              placeholder="Chat from user"
              hide-details
              required class="pa-0 ma-0" outlined dense v-model="bulkChatUser">
            </v-autocomplete>
            </v-col>
        </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn outlined @click="chatFromUserDialog = false;liveStageL = false;">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="liveStageL"  @click="liveStage('bulk')">Activate</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
import axios from 'axios';
import { mapState } from 'vuex';
import dayjs from 'dayjs';
// import relativeTime from 'dayjs/plugin/relativeTime';
// import customParseFormat from 'dayjs-ext/plugin/customParseFormat';
import addCompany from './dialogs/AddCompany';
import addTouchpoint from './dialogs/AddTouchpoint';
import stageDetails from './dialogs/StageDetails';
import companySummary from './companySummary';
import addAdmin from './dialogs/AddAdmin';
import moment from 'moment';
import VueSweetalert2 from 'vue-sweetalert2';
import draggable from "vuedraggable";
import 'sweetalert2/dist/sweetalert2.min.css';
import AmaraLoader from './elements/amara-loader';

Vue.use(VueSweetalert2);
  export default {
    name: 'HelloWorld',

    components: {
      axios,
      dayjs,
      // relativeTime,
      // customParseFormat,
      addCompany,
      addTouchpoint,
      stageDetails,
      addAdmin,
      companySummary,
      draggable,
      AmaraLoader
    },

    data: () => ({
      reportItem: [
        {
          label: 'All chat',
          value: 'all'
        },
        {
          label: 'latest completed chat',
          value: 'latest'
        }
      ],
      reportType: '',
      reportloading: false,
      dialogOpen: false,
      deactivateStageL: false,
      liveStageL: false,
      deleteStageL: false,
      chatFromUserDialog: false,
      loaderforAction: false,
      loaderforActionBulk: false,
      sortBy: '',
      items: [{
        name: 'Shortest to longest duration',
        value: 'increasing_duration'
      }, {
        name: 'Longest to shortest duration',
        value: 'decreasing_duration'
      }],
      bulkChatUser: '',
      reportDialog: false,
      bulkActionTouchpoint: [],
      companyAdminsParent: [],
      companyTouchpointsBulk: [],
      selectAll: false,
      blacklistAction: [
          {
            title: 'Bulk activate touchpoint',
            value: 1
          }, {
            title: 'Bulk deactivate touchpoint',
            value: 2
          }, {
            title: 'Bulk delete touchpoint',
            value: 3
          }
        ],
      moment: moment,
      menu: false,
      totalCount: '',
      avatarList: [],
      driverList: [],
      updatedList: {},
      touchpointList: [],
      logDetails: false,
      dialogSorting: false,
      logData: [],
      auditLog: [],
      data: [],
      selectedDriver: '',
      selectedSubDriver: '',
      subdriverName: '',
      subDriverDescription: '',
      pagination: {
        page: 1,
        rowsPerPage: 20
      },
      subdriverDialog: false,
      autoAssignItem: [{
        name: 'HR Manager',
        value: 'hr_manager'
      }, {
        name: 'HRBP',
        value: 'hrbp'
      }],
      LinkItem: [{
        name: 'Glassdoor',
        value: 'glassdoor_link'
      }, {
        name: 'JobStreet',
        value: 'jobStreet_link'
      }, {
        name: 'Linkedin',
        value: 'linkedin_link'
      }, {
        name: 'Facebook',
        value: 'facebook_link'
      }],
      tabs: null,
      editView: false,
      loading: false,
      companyData: null,
      companyTouchpoints: [],
      selectedStage: '',
      companyAdmins: [],
      companyDataOld: [],
      planOld: [],
      settingsOld: [],
      company: {},
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }),
  computed: {
    ...mapState({
      snackbar: state => state.snackbar,
      user: state => state.user
    }),
    getPreNumber () {
      const num = (this.pagination.page - 1) * 20 + 1;
      if (num > this.totalCount) {
        return this.totalCount;
      }
      return num;
    },
    getNextNumber () {
      const num = (this.pagination.page - 1) * 20 + 20;
      if (num > this.totalCount) {
        return this.totalCount;
      }
      return num;
    }
  },
    methods: {
      sortByNew () {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        let url = '';
        if (this.sortBy) {
          url = `${process.env.VUE_APP_API_URL}stage/list/${this.$route.query.cid}?count=true&stageType__in=lifecycle&fields=id,title&sorting=${this.sortBy}`
        } else {
          url = `${process.env.VUE_APP_API_URL}stage/list/${this.$route.query.cid}?count=true&stageType__in=lifecycle&fields=id,title`
        }

        axios.get(url).then((response) => {
          if (response.data ) {
            this.touchpointList = response.data;
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch audit logs, Please try again later!'
            });
          }
        });
      },
      deactivateStage (id1, item) {
        this.$swal({
          title: 'Are you sure?',
          text: "You want to deactivate this touchpoint!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, deactivate it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.deactivateStageL = true;
            this.selectedStage = id1;
            let temp = [];
            if (item === 'bulk') {
              const touchpoint = this.bulkActionTouchpoint;
              this.$lodash.each(touchpoint, (val, i) => {
                let temp1 = {}
                temp1.stage_id = val.stage_details.id,
                temp1.status = "draft"
                temp.push(temp1);
              });
            } else {
                let temp1 = {}
                temp1.stage_id = this.selectedStage,
                temp1.status = "draft"
                temp.push(temp1);
            }
            axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
            axios.patch(`${process.env.VUE_APP_API_URL}stage/bulk-update/`, {
                updated_stages: temp
            }).then((response) => {
              if (response && response.data) {
                  this.reportDialog = false;
                  this.getSatges(this.$route.query.cid);
                  this.getstagesBulk(this.$route.query.cid);
                  this.$store.dispatch('updateSnackbar', {
                    color: 'success',
                    show: true,
                    text: 'Touchpoint deactivated successfully!'
                  });
                // this.$refs.stagedetails.stageDialog = true;
                // this.$refs.stagedetails.stageData = response.data;
                // console.log(this.company);
              }
            }).catch((err) => {
              this.deactivateStageL = false;
              this.liveStageL = false;
              this.deleteStageL = false;
              if (err.response.status === 429) {
                this.config.savingStage = false;
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: err.response.data.error
                });
              } else if (err.response.status === 400) {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: err.response.data.message
                });
              } else {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: 'Unable to fetch details, Please try again later!'
                });
              }
            });
          }
        });
        },
      deleteStage (id1, item) {
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this touchpoint!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteStageL = true;
            this.selectedStage = id1;
            let temp = [];
            if (item === 'bulk') {
              const touchpoint = this.bulkActionTouchpoint;
              this.$lodash.each(touchpoint, (val, i) => {
                let temp1 = {}
                temp1.stage_id = val.stage_details.id,
                temp.push(temp1);
              });
            } else {
                let temp1 = {}
                temp1.stage_id = this.selectedStage,
                temp.push(temp1);
            }
            // console.log('teststtsst', temp);
            let updated_stages = {
              updated_stages: temp
            }
            axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
            axios.delete(`${process.env.VUE_APP_API_URL}stage/bulk-delete/`, {
              data: {
                  updated_stages: temp
              }
              }).then((response) => {
              if (response && response.data) {
                this.reportDialog = false;
                  this.getSatges(this.$route.query.cid);
                  this.getstagesBulk(this.$route.query.cid);
                  this.$store.dispatch('updateSnackbar', {
                    color: 'success',
                    show: true,
                    text: 'Touchpoint deactivated successfully!'
                  });
                // this.$refs.stagedetails.stageDialog = true;
                // this.$refs.stagedetails.stageData = response.data;
                // console.log(this.company);
              }
            }).catch((err) => {
              this.deactivateStageL = false;
              this.liveStageL = false;
              this.deleteStageL = false;
              if (err.response.status === 429) {
                this.config.savingStage = false;
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: err.response.data.error
                });
              } else if (err.response.status === 400) {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: err.response.data.message
                });
              } else {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: 'Unable to fetch details, Please try again later!'
                });
              }
            });
          }
        });
        },
      liveStage (item) {
        this.liveStageL = true;
        if (this.bulkChatUser) {
        let temp = [];
        if (item === 'bulk') {
          const touchpoint = this.bulkActionTouchpoint;
          this.$lodash.each(touchpoint, (val, i) => {
            let temp1 = {}
            temp1.chatFromUser_id = this.bulkChatUser,
            temp1.stage_id = val.stage_details.id,
            temp1.status = "live"
            temp.push(temp1);
          });
        } else {
            let temp1 = {}
            temp1.chatFromUser_id = this.chat_on_behalf,
            temp1.stage_id = this.$parent.selectedStage,
            temp1.status = "live"
            temp.push(temp1);
        }
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.patch(`${process.env.VUE_APP_API_URL}stage/bulk-update/`, {
          updated_stages: temp
        }).then((response) => {
          if (response && response.data) {
            this.reportDialog = false;
            this.chatFromUserDialog = false;
            this.getSatges(this.$route.query.cid);
            this.getstagesBulk(this.$route.query.cid);
            this.$store.dispatch('updateSnackbar', {
              color: 'success',
              show: true,
              text: 'Touchpoint activated!'
            });
          }
        }).catch((err) => {
          this.deactivateStageL = false;
          this.liveStageL = false;
          this.deleteStageL = false;
          if (err.response.status === 429) {
            this.loading = false;
            this.config.savingStage = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else if (err.response.status === 400) {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: err.response.data.message
                });
              } else {
            this.loading = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
        } else {
          this.liveStageL = false;
          this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Please select chat from user!'
            });
        }
      },
      bulkActionCheck (value) {
        this.reportDialog = true;
        // if (value === 1) {
        //   this.$refs.stageDetails.liveStage('bulk');
        // } 
        // if (value === 2) {
        //   this.deactivateStage(null, 'bulk');
        // } 
        // if (value === 3) {
        //   this.deleteStage(null, 'bulk')
        // }
      },
      onEnd () {
        console.log(this.touchpointList);
      },
      saveSortList () {
        const temp = {};
        this.$lodash.each(this.touchpointList.data, (val, i) => {
            temp[`${val.stage_details.id}`] = i
          });
          console.log(temp);
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.patch(`${process.env.VUE_APP_API_URL}stage/update/index/${this.$route.query.cid}`, {
          'updated_index_dict': temp
        }).then((response) => {
          if (response && response.data) {
            this.getSatges(this.$route.query.cid);
            this.dialogSorting = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'success',
              show: true,
              text: 'Touchpoint index updated successfully!'
            });
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to update touchpoint index, Please try again later!'
            });
          }
        });
      },
      downloadTouchpointSheet (link) {
        setTimeout(() => {
          window.open(link);
        }, 100);
      },
      getAvatarAudit (a) {
        let avatar = '';
        this.$lodash.each(this.avatarList, (val, i) => {
          if (val.id == a) {
            avatar = val.avatar;
          }
        });
        return avatar;
      },
      displayPre () {
        if (this.pagination.page > 1) {
          this.pagination.page = this.pagination.page - 1;
          this.getAuditLog()
        }
      },
      displayNext () {
        if (this.pagination.page < (this.totalCount / 20)) {
          this.pagination.page = this.pagination.page + 1;
          this.getAuditLog()
        }
      },
      getAuditLog ($state) {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.get(`${process.env.VUE_APP_API_URL}company/audit`, {
          params: {
            'page_offset': this.pagination.page,
            'page_limit': this.pagination.rowsPerPage,
            'company_id': this.$route.query.cid
          }
        }).then((response) => {
          if (response.data && response.data.count ) {
            this.totalCount = response.data.count;
          }
          if (response && response.data && response.data.results) {
            this.auditLog = response.data.results;
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch audit logs, Please try again later!'
            });
          }
        });
      },
      infiniteHandler ($state) {
        console.log($state, 'abcstate');
        this.getAuditLog($state);
      },
      viewLogDetails (data) {
        this.logDetails = true;
        this.logData = data;
      },
      addNewAvatar () {
        this.avatarList.push({
          avatar: ''
        })
      },
      getIcon (type) {
        let icon = 'mdi-office-building-outline';
        if (type === 'user_logged_in') {
          icon = 'mdi-account-arrow-left-outline'
        }
        if (type === 'user_login_failed') {
          icon = 'mdi-account-remove-outline'
        }
        if (type === 'reminder') {
          icon = 'mdi-bell-ring-outline'
        }
        if (type === 'company_added') {
          icon = 'mdi-office-building-outline'
        }
        if (type === 'driver_deleted' || type === 'driver_updated' || type === 'driver_added') {
          icon = 'mdi-lifebuoy'
        }
        if (type === 'company_settings') {
          icon = 'mdi-office-building-outline'
        }
        if (type === 'company_profile') {
          icon = 'mdi-office-building-outline'
        }
        if (type === 'plan_settings') {
          icon = 'mdi-office-building-outline'
        }
        if (type === 'user_logged_out') {
          icon = 'mdi-account-arrow-right-outline'
        }
        if (type === 'chat_feedback') {
          icon = 'mdi-download-outline'
        }
        if (type === 'report_download') {
          icon = 'mdi-file-download-outline'
        }
        if (type === 'touchpoint_upload') {
          icon = 'mdi-map-marker-path'
        }
        if (type === 'admin_added') {
          icon = 'mdi-account-plus-outline'
        }
        return icon;
      },
      getColor (type) {
        let color = 'indigo lighten-4';
        if (type === 'user_logged_in') {
          color = 'green lighten-4'
        }
        if (type === 'user_login_failed') {
          color = 'red lighten-4'
        }
        if (type === 'user_logged_out') {
          color = 'green lighten-4'
        }
        if (type === 'reminder') {
          color = 'indigo lighten-4'
        }
        if (type === 'driver_deleted' || type === 'driver_updated' || type === 'driver_added') {
          color = 'indigo lighten-4'
        }
        if (type === 'company_added') {
          color = 'indigo lighten-4'
        }
        if (type === 'plan_settings') {
          color = 'indigo lighten-4'
        }
        if (type === 'company_settings') {
          color = 'indigo lighten-4'
        }
        if (type === 'company_profile') {
          color = 'indigo lighten-4'
        }
        if (type === 'chat_feedback') {
          color = 'indigo lighten-4'
        }
        if (type === 'touchpoint_upload') {
          color = 'indigo lighten-4'
        }
        if (type === 'report_download') {
          color = 'indigo lighten-4'
        }
        if (type === 'admin_added') {
          color = 'indigo lighten-4'
        }
        return color;
      },
      closeDialog () {
        this.subdriverDialog = false;
        this.selectedDriver = '';
        this.selectedSubDriver = '';
        this.subdriverName = '';
        this.subDriverDescription = '';
      },
      addNewSubDriver (driver) {
        this.selectedDriver = driver;
        this.subdriverDialog = true;
      },
      editSubDriver (driver, subdriver) {
        this.selectedDriver = driver;
        this.selectedSubDriver = subdriver;
        this.subdriverName = subdriver.sub_driver;
        this.subdriverDialog = true;
      },
      saveSubdriver () {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.post(`${process.env.VUE_APP_API_URL}company/drivers?company_id=${this.$route.query.cid}`, {
          driver: this.selectedDriver,
          sub_driver: this.subdriverName,
          sub_driver_description: this.subDriverDescription ? this.subDriverDescription : undefined,
        }).then((response) => {
          if (response && response.data) {
            this.closeDialog();
            this.getDrivers();
            this.subdriverName = '';
            this.subDriverDescription = '';
            this.selectedDriver = '';
            this.selectedSubDriver = '';
            this.$store.dispatch('updateSnackbar', {
              color: 'success',
              show: true,
              text: 'Subdriver has been added successfully!'
            });
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to add subdriver, Please try again later!'
            });
          }
        });
      },
      updateSubdriver () {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.post(`${process.env.VUE_APP_API_URL}company/drivers?company_id=${this.$route.query.cid}&id=${this.selectedSubDriver.id}`, {
          driver: this.selectedDriver,
          sub_driver: this.subdriverName,
          sub_driver_description: this.subDriverDescription ? this.subDriverDescription : undefined,
        }).then((response) => {
          if (response && response.data) {
            this.closeDialog();
            this.getDrivers();
            this.subdriverName = '';
            this.subDriverDescription = '';
            this.selectedDriver = '';
            this.selectedSubDriver = '';
            this.$store.dispatch('updateSnackbar', {
              color: 'success',
              show: true,
              text: 'Subdriver has been added successfully!'
            });
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to add subdriver, Please try again later!'
            });
          }
        });
      },
      deleteSubDriver (subdri) {
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this sub driver!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete!'
        }).then((result) => {
          if (result.isConfirmed) {
            axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
            axios.delete(`${process.env.VUE_APP_API_URL}company/drivers?company_id=${this.$route.query.cid}&id=${subdri.id}`).then((response) => {
              if (response && response.data) {
                this.getDrivers();
                this.$store.dispatch('updateSnackbar', {
                  color: 'success',
                  show: true,
                  text: 'Sub driver deleted successfully!'
                });
                // this.$refs.stagedetails.stageDialog = true;
                // this.$refs.stagedetails.stageData = response.data;
                // console.log(this.company);
              }
            }).catch((err) => {
              if (err.response.status === 429) {
                this.config.savingStage = false;
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: err.response.data.error
                });
              } else {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: 'Unable to trigger reminders, Please try again later!'
                });
              }
            });
          }
        });
      },
      changeTab (url) {
        if (url === 'company-list' && this.$route.query.page) {
          this.$router.push(`/${url}?page=${this.$route.query.page}`);
        } else {
          this.$router.push(`/${url}`);
        }
    },
    formatDate1 (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    sendReminder () {
      this.$swal({
          title: 'Are you sure?',
          text: "You want to trigger reminders!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, send!'
        }).then((result) => {
          if (result.isConfirmed) {
            axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
            axios.get(`${process.env.VUE_APP_API_URL}reminder/trigger_reminders/${this.$route.query.cid}`, {
                status: 'draft'
            }).then((response) => {
              if (response && response.data) {
                  this.$store.dispatch('updateSnackbar', {
                    color: 'success',
                    show: true,
                    text: 'Chat reminders queued successfully!'
                  });
              }
            }).catch((err) => {
              if (err.response.status === 429) {
                this.config.savingStage = false;
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: err.response.data.error
                });
              } else {
                this.$store.dispatch('updateSnackbar', {
                  color: 'error',
                  show: true,
                  text: 'Unable to trigger reminders, Please try again later!'
                });
              }
            });
          }
        });
    },
    parseDate (date) {
      if (!date) return null;
      const [day, month, year] = date.split('-');
      return `${year}-${month}-${day}`;
    },
      editDetails (companyData) {
        delete companyData.company_details.tags;
        delete companyData.company_details.users_status;
        delete companyData.company_settings.language_configured;
        delete companyData.company_settings.employee_tags;
        this.$store.state.activeCompany.company_details = JSON.stringify(companyData.company_details);
        this.$store.state.activeCompany.company_settings = JSON.stringify(companyData.company_settings);
        this.$store.state.activeCompany.plan_details = JSON.stringify(companyData.plan_details);
        this.$store.dispatch('updateCompany', this.$store.state.activeCompany);
        // this.$store.dispatch('updateCompanySettings', companyData.company_settings);
        // this.$store.dispatch('updateCompanyPlan', companyData.plan_details);
        this.$refs.addcompany.dialog = true;
        this.$refs.addcompany.company_settings = companyData.company_settings;
        // this.$refs.addcompany.company_settings.pause_interaction_start_date = moment(companyData.company_settings.pause_interaction_start_date).format('DD-MM-YYYY');
        // this.$refs.addcompany.company_settings.pause_interaction_end_date = moment(companyData.company_settings.pause_interaction_end_date).format('DD-MM-YYYY');
        this.$refs.addcompany.companyAvatar[0].company_avatar = companyData.company_details.company_avatar;
        this.$refs.addcompany.editView = true;
        this.$refs.addcompany.company = companyData.company_details;
        if (companyData && companyData.company_details && companyData.company_details.activatedAt) {
          const [year, month, day] = companyData.company_details.activatedAt.split('-');
          this.$refs.addcompany.company.activatedAt = `${day}-${month}-${year}`;
        }
        // this.$refs.addcompany.company.activatedAt = companyData.company_details.activatedAt;
        console.log('test', this.$refs.addcompany.plan);
        this.$refs.addcompany.plan = companyData.plan_details;
        console.log('test2', this.$refs.addcompany.plan);
        this.planOld = companyData.plan_details;
        this.$refs.addcompany.company_settings = companyData.company_settings;
        // this.$refs.addcompany.start_date_formated = companyData.company_settings.pause_interaction_start_date;
        // this.$refs.addcompany.end_date_formated = companyData.company_settings.pause_interaction_end_date;
        this.settingsOld = companyData.company_settings;
      },
      downloadRatingReports () {
      this.$store.dispatch('updateSnackbar', {
        color: 'warn',
        show: true,
        text: 'Generating report, Please wait!'
      });
      axios.get(`${process.env.VUE_APP_API_URL}company/policy/download/${this.$route.query.cid}`).then((response) => {
        if (response && response.data && response.data.success) {
          if (response.data && response.data.report_url) {
            this.$forceUpdate();
            setTimeout(() => {
              window.open(response.data.report_url);
            }, 100);
          } else {
            this.downloadingFlag = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to generate report, Please try again later!'
            });
          }
          this.reportDialog = false;
        } else {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to generate report, Please try again later!'
          });
          this.reportDialog = false;
        }
        this.downloadingFlag = false;
      }).catch((err) => {
        if (err.response.status === 429) {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.error
          });
        } else {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to generate report, Please try again later!'
          });
        }
      });
      },
      downloadTouchpointReports () {
      this.$store.dispatch('updateSnackbar', {
        color: 'warn',
        show: true,
        text: 'Generating report, Please wait!'
      });
      axios.get(`${process.env.VUE_APP_API_URL}stage/export/${this.$route.query.cid}`).then((response) => {
        if (response && response.data && response.data.file_url) {
          if (response.data && response.data.file_url) {
            this.$forceUpdate();
            setTimeout(() => {
              window.open(response.data.file_url);
            }, 100);
          } else {
            this.downloadingFlag = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to generate report, Please try again later!'
            });
          }
          this.reportDialog = false;
        } else {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to generate report, Please try again later!'
          });
          this.reportDialog = false;
        }
        this.downloadingFlag = false;
      }).catch((err) => {
        if (err.response.status === 429) {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.error
          });
        } else {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to generate report, Please try again later!'
          });
        }
      });
      },
      downloadLoginReports () {
      this.$store.dispatch('updateSnackbar', {
        color: 'warn',
        show: true,
        text: 'Generating report, Please wait!'
      });
      axios.get(`${process.env.VUE_APP_API_URL}company/login-logs/download/${this.$route.query.cid}`).then((response) => {
        if (response && response.data && response.data.success) {
          if (response.data && response.data.report_url) {
            this.$forceUpdate();
            setTimeout(() => {
              window.open(response.data.report_url);
            }, 100);
          } else {
            this.downloadingFlag = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to generate report, Please try again later!'
            });
          }
          this.reportDialog = false;
        } else {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to generate report, Please try again later!'
          });
          this.reportDialog = false;
        }
        this.downloadingFlag = false;
      }).catch((err) => {
        if (err.response.status === 429) {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.error
          });
        } else {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to generate report, Please try again later!'
          });
        }
      });
      },
      downloadRating (i) {
      this.reportloading = true;
      this.$store.dispatch('updateSnackbar', {
        color: 'warn',
        show: true,
        text: 'Generating report, Please wait!'
      });
      const queryParams = {
        latest_completed_chat: i === 'latest' ? true : undefined
      };
      axios.get(`${process.env.VUE_APP_API_URL}report/feedback_report/${this.$route.query.cid}`, {
        params: queryParams
      }).then((response) => {
        this.dialogOpen = false;
        this.reportType = '';
        if (response && response.data && response.data.success) {
          if (response.data && response.data.report_url) {
            this.$forceUpdate();
            setTimeout(() => {
              window.open(response.data.report_url);
            }, 100);
          } else {
            this.downloadingFlag = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to generate report, Please try again later!'
            });
          }
          this.reportDialog = false;
        } else {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to generate report, Please try again later!'
          });
          this.reportDialog = false;
        }
        this.reportloading = false;
        this.downloadingFlag = false;
      }).catch((err) => {
        this.dialogOpen = false;
        this.reportloading = false;
        this.reportType = '';
        if (err.response.status === 429) {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: err.response.data.error
          });
        } else {
          this.downloadingFlag = false;
          this.$store.dispatch('updateSnackbar', {
            color: 'error',
            show: true,
            text: 'Unable to generate report, Please try again later!'
          });
        }
      });
      },
      resendEmail (id){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to resend credentials email again!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, resend!'
        }).then((result) => {
          if (result.isConfirmed) {
            axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
            axios.get(`${process.env.VUE_APP_API_URL}users/generate/password/${id}`).then((response) => {
              this.loading = false;
              this.open = false;
              this.$store.dispatch('updateSnackbar', {
                color: 'success',
                show: true,
                text: 'Email sent successfully!'
              });
              this.$parent.getAdmins(this.$route.query.cid);
              this.getAdmins(this.$route.query.cid)
            }, (response) => {
              this.$store.dispatch('updateSnackbar', {
                color: 'error',
                show: true,
                text: 'Unable to upload admin, Please try again later!'
              });
              throw new Error(response);
            });
          }
        });
      
    },
      getAdmins (id) {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.get(`${process.env.VUE_APP_API_URL}users/admins/${id}`).then((response) => {
          if (response && response.data) {
            this.companyAdmins = response.data.data;
            // console.log(this.company);
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.config.savingStage = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
      getSatgeDetails (id, id1, item) {
        this.$refs.stagedetails.getAdmins(this.$route.query.cid);
        this.selectedStage = id1;
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        
        axios.get(`${process.env.VUE_APP_API_URL}survey/${id}/details?fields=title,type,description,company,createdAt,createdBy,lastUpdated,interactions,interactions_ms,interactions_tl,stage_status`).then((response) => {
          if (response && response.data) {
            if (item.chatFromUser) {
              console.log('ugduyayusdcgyuasgcuasoyucdgsudc');
              this.$refs.stagedetails.chat_on_behalf = item.chatFromUser;
            } else {
              this.$refs.stagedetails.chat_on_behalf = '';
            }
            this.$refs.stagedetails.stageDialog = true;
            this.$refs.stagedetails.stageData = response.data;
            // console.log(this.company);
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.config.savingStage = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
      getDrivers () {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.get(`${process.env.VUE_APP_API_URL}company/drivers?company_id=${this.$route.query.cid}`).then((response) => {
          if (response && response.data) {
            this.driverList = response.data;
            // console.log(this.company);
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.config.savingStage = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
      saveDetails () {
        this.editView = false;
      },
      openAdminModal (data) {
        this.$refs.addadmin.open = true;
        this.$refs.addadmin.candidate = {};
        if (data) {
          this.$refs.addadmin.editView = true;
          this.$refs.addadmin.candidate = data;
        }
      },
      openTouchpointModal () {
        this.$refs.addtouchpoint.dialogTouchpoint = true;
      },
      openSortingModal () {
        this.touchpointList = {};
        this.sortBy = '';
        this.loaderforAction = true;
        this.dialogSorting = true;
        this.getTouchpointList ();
      },
      getTouchpointList () {
        this.loaderforAction = true;
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.get(`${process.env.VUE_APP_API_URL}/stage/list/${this.$route.query.cid}?count=true&stageType__in=lifecycle&fields=id,title`).then((response) => {
          if (response && response.data && response.data) {
            this.touchpointList = response.data;
            this.loaderforAction = false;
          }
        }).catch((err) => {
          this.loaderforAction = false;
          if (err.response.status === 429) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
      getAvatar () {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.get(`${process.env.VUE_APP_API_URL}company/avatar`).then((response) => {
          if (response && response.data) {
            this.avatarList = response.data;
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
      openModal () {
        this.$refs.addcompany.dialog = true;
        this.$refs.addcompany.company = {};
        this.$refs.addcompany.editView = false;
        this.$refs.addcompany.company.companies_avatar = this.avatarList;
        this.$refs.addcompany.company.is_active = true;
        this.$refs.addcompany.company.chat_cobranding_enabled = false;
        this.$refs.addcompany.company.allow_stage_edit = false;
        this.$refs.addcompany.companyAvatar[0].company_avatar = this.companyData.company_details.company_avatar;
      },
      getstagesBulk (id) {
        this.loaderforActionBulk = true
        this.bulkActionTouchpoint = [];
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        const queryParams = {
        count: 'true',
        stageType__in: 'lifecycle',
      };
        axios.get(`${process.env.VUE_APP_API_URL}stage/list/${id}`, {
          params: queryParams
        }).then((response) => {
          if (response && response.data) {
            this.companyTouchpointsBulk = response.data.data;
            this.loaderforActionBulk = false;
            // console.log(this.company);
          }
        }).catch((err) => {
          this.loaderforActionBulk = false;
          if (err.response.status === 429) {
            this.config.savingStage = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
      getSatges (id) {
        this.bulkActionTouchpoint = [];
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        const queryParams = {
        count: 'true',
        page_limit: this.pagination.rowsPerPage,
        page_offset: this.pagination.page || 1,
        stageType__in: 'lifecycle',
      };
        axios.get(`${process.env.VUE_APP_API_URL}stage/list/${id}`, {
          params: queryParams
        }).then((response) => {
          if (response && response.data) {
            this.pagination.length = Math.ceil(response.data.count / this.pagination.rowsPerPage);
            this.companyTouchpoints = response.data.data;
            this.deactivateStageL = false;
            this.liveStageL = false;
            this.deleteStageL = false;
            // console.log(this.company);
          }
        }).catch((err) => {
          this.deactivateStageL = false;
          this.liveStageL = false;
          this.deleteStageL = false;
          if (err.response.status === 429) {
            this.config.savingStage = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
      getCompanyDetails (id) {
        axios.defaults.headers.common.Authorization = `JWT ${this.user.access_token}`;
        axios.get(`${process.env.VUE_APP_API_URL}company/profile/${id}?fields=company_name,brand_name,spoc_name,allow_stage_edit,spoc_email,spoc_phone,country,company_address,company_logo,createdBy,company_type,chat_cobranding_enabled,createdAt,is_active,employee_strength,chat_cobranding_enabled,tags,facebook_link,linkedin_link,glassdoor_link,jobStreet_link,activatedAt,user_booked,users_status,companies_avatar,company_avatar`).then((response) => {
          if (response && response.data) {
            if (response.data.plan_details && response.data.plan_details.title) {
              if (response.data.plan_details.title.split(' - ')[1]) {
                response.data.plan_details.title = response.data.plan_details.title.split(' - ')[1]
              }
            }
            this.companyData = response.data;
            if (response.data.company_details && response.data.company_details.activatedAt) {
              this.companyData.company_details.activatedAt = response.data.company_details.activatedAt.split(' ')[0];
            }
            if (this.companyData.company_details.activatedAt ) {
              const [year, month, day] = companyData.company_details.activatedAt.split('-');
              this.companyData.company_details.activatedAt = `${day}-${month}-${year}`;
            }
            if (this.$route.query.editview) {
              // this.editDetails(this.companyData);
            }
            // setTimeout(() => {
            //   if (this.$route.query.editview) {
            //       this.editDetails(response.data);
            //     }
            // }, 1000);
            // console.log(this.company);
          }
        }).catch((err) => {
          if (err.response.status === 429) {
            this.config.savingStage = false;
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: err.response.data.error
            });
          } else {
            this.$store.dispatch('updateSnackbar', {
              color: 'error',
              show: true,
              text: 'Unable to fetch details, Please try again later!'
            });
          }
        });
      },
    },
    mounted () {
      this.getCompanyDetails(this.$route.query.cid);
      this.getAdmins(this.$route.query.cid);
      this.getAvatar();
      this.getstagesBulk(this.$route.query.cid);
      if (this.$route.query && this.$route.query.touchpoint) {
        this.tabs = 'c_touchpoints';
        this.getSatges(this.$route.query.cid);
        this.getstagesBulk(this.$route.query.cid);
      }
    }
  }
</script>
<style lang="scss" >
  .Toolbarext {
    .v-toolbar__extension {
      width: 74% !important;
      }
  }

.heading {
  font-size: 16px;
  padding-top:10px;
}
.dashboardCount {
  font-size: 70px;
  padding:10%;
}
.mainBox:hover {
  transform: scale(1.05);
}
.mainBox {
      background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 16px 11px rgb(0 0 0 / 6%), 0 4px 8px rgb(0 0 0 / 10%) !important;
    height: 16rem;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    text-align: center;
}
.tableDash {
  text-align: left;
  padding: 10px;;
  background-color: white;
    border-radius: 6px;
    box-shadow: 0 4px 16px 11px rgb(0 0 0 / 6%), 0 4px 8px rgb(0 0 0 / 10%) !important; 
    margin-top:15px;
}
.onlyThis .v-toolbar__content {
  display: none !important;
}
// p {
//   font-weight: bold;
// }
.app--text {
  color: #050550 !important;
}
.primary--text {
  color: #050550 !important;
}
.disabledView {
    cursor: not-allowed;
    pointer-events: none;
}
.avatarBox {
  border: 1px solid #eaeaea;
  border-radius:6px;
}
.editAvatarBox {
  pointer-events: initial;
}
.editAvatarBox:hover {
  border: 2px solid #050550;
  cursor: pointer;
}
.am-card-animation {
  border-radius: 6px;
  transition: all .4s;
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.am-table-title {
  font-size: 14px !important;
  line-height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}
.am-table-sub-title {
  font-size: 12px;
  line-height: 18px;
  color: #878a99;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}


</style>
